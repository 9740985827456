<template>
  <v-container style="max-width: unset">
    <v-row v-resize="onResize" no-gutters>
      <v-col :cols="nvaCols">
        <v-btn
          class="mb-3"
          :title="$t('library.categories')"
          v-if="innerWidth < 1340"
          @click="treeDrawer = !treeDrawer"
        >
          <v-icon>mdi-view-list</v-icon>

          {{ $t("library.categories") }}
        </v-btn>

        <v-navigation-drawer
          v-model="treeDrawer"
          :right="$vuetify.rtl"
          mobile-breakpoint="1340"
          absolute
          :width="treeDrawerWidth"
          :color="$vuetify.theme.dark ? '#1E1E1E' : '#FFFFFF'"
          floating
          class="book-tree"
          style="z-index: 4"
        >
          <v-card outlined height="100%" :loading="loadingDataCategories" class="pa-0">
            <v-card-title>{{ $t("library.categories") }}</v-card-title>
            <v-divider></v-divider>
            <v-card-text  class="pa-1">
              <v-text-field
                v-model="searchCategory"
                append-icon="mdi-magnify"
                :label="$t('search')"
                single-line
                hide-details
                class="pt-0 pb-2"
              ></v-text-field>
              <v-treeview
                v-if="CategoriesTree"
                :items="CategoriesTree"
                :active.sync="active"
                activatable
                item-key="guid"
                item-text="categoryName"
                item-children="children"
                transition
                ref="treeReference"
                :open.sync="initiallyOpen"
                hoverable
                open-all
                @update:active="getBooks"
                :search="searchCategory"

              >
                <template v-slot:label="{ item }">
                  <v-row :title="item.categoryName">
                    <v-col align-self="center">
                      <v-list-item class="ma-0 pa-0" dense >
                        <v-list-item-content  class="ma-0 pa-0">
                          <v-list-item-title  class="ma-0 pa-0 text-wrap" >
                            {{ item.categoryName }}
                            </v-list-item-title
                          >
                        </v-list-item-content>
                        <v-list-item-action  class="ma-0 pa-0">
                           <v-chip    >  {{ item.booksCount }}</v-chip>
                        </v-list-item-action>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </template>
              </v-treeview>
            </v-card-text>
          </v-card>
        </v-navigation-drawer>
      </v-col>
      <v-col :cols="booksCols">
        <!-- <v-row>
          <v-col cols="12" md="12">
            <v-expansion-panels focusable flat>
              <v-expansion-panel>
                <v-expansion-panel-header>{{
                  $t("degrees.searchFilters")
                }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" md="3" sm="12" class="mt-6">
                      <v-select
                        dense
                        :items="examTypeList"
                        :label="$t('degrees.examType')"
                        v-model="typeExam"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <v-text-field
                        v-model="searchBook"
                        append-icon="mdi-magnify"
                        :label="$t('writeWhatYouSearch')"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row> -->
        <v-row v-if="bookId == '00000000-0000-0000-0000-000000000000'">
          <v-col cols="12" md="12" sm="12">
            <v-text-field
              v-model="searchBook"
              append-icon="mdi-magnify"
              :label="$t('writeWhatYouSearch')"
              outlined
              dense
              hide-details
              v-if="books[0]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-item-group
          v-model="bookKeysSelected"
          v-if="bookId == '00000000-0000-0000-0000-000000000000'"
        >
          <v-row>
            <v-col
              v-for="item in visiblePages"
              :key="item.bookGuid"
              cols="12"
              lg="3"
              md="4"
              sm="6"
              align="center"
            >
              <v-item v-slot="{ active, toggle }" :value="item">
                <v-hover v-slot="{ hover }" open-delay="0">
                  <v-card
                    outlined
                    :elevation="hover ? 6 : 0"
                    :class="{ 'on-hover': hover }"
                    class="books"
                    max-width="360"
                    min-width="200"
                    max-height="460"
                    min-height="460"
                    flat
                    :color="
                      active
                        ? $vuetify.theme.dark
                          ? '#505050'
                          : '#e5e5e5'
                        : ''
                    "
                    @click="toggle;openBook(item);"
                  >
                    <!-- <v-btn
                      fab
                      class="ma-1 px-0"
                      style="z-index: 1"
                      x-small
                      absolute
                      color="secondary"
                      :title="$t('lecOne.lecDownlaod')"
                    >
                      <v-icon class="pa-0">mdi-download</v-icon>
                    </v-btn> -->
                    <v-img :src="item.coverImageUrl"> </v-img>
                    <v-footer
                      width="100%"
                      class="pa-0"
                      absolute
                      color="rgb(0, 0, 0, 0)"
                    >
                      <v-card-title
                        style="
                          background-color: rgb(0 0 0 / 55%);
                          width: 100%;
                          word-break: break-word;
                        "
                        dir="auto"
                      >
                        {{ item.bookTitle }}
                        <v-card-text class="pa-0">
                          <v-card-subtitle
                            class="pa-0 text-center mx-auto amber--text"
                          >
                            {{ item.author }}
                          </v-card-subtitle>
                        </v-card-text>
                      </v-card-title>
                    </v-footer>
                  </v-card>
                </v-hover>
              </v-item>
            </v-col>
            <v-col cols="12" md="12">
              <v-pagination
                v-if="books[0]"
                v-model="page"
                :length="Math.ceil(booksFilter.length / perPage)"
                total-visible="15"
              ></v-pagination>
            </v-col>
          </v-row>
        </v-item-group>
        <v-card outlined v-else-if="books[0]">
          <v-row>
            <v-col cols="12" lg="3" md="3" sm="3" align="center">
              <v-card-text>
                <v-img
                  max-height="460"
                  max-width="360"
                  :src="books[0].coverImageUrl"
                >
                </v-img>
              </v-card-text>
            </v-col>
            <v-col cols="12" lg="9" md="9" sm="9">
              <v-card-title>
                {{ books[0].bookTitle }}
              </v-card-title>

              <v-card-text>
                <v-card-title class="pa-0 pb-6 amber--text">
                  {{ books[0].author }}
                </v-card-title>
                <v-card-subtitle class="pa-0 py-1" v-if="books[0].translator">
                  <span class="primary--text">{{
                    $t("library.translator")
                  }}</span>
                  : {{ books[0].translator }}
                </v-card-subtitle>
                <v-card-subtitle class="pa-0 py-1" v-if="books[0].investigator">
                  <span class="primary--text">{{
                    $t("library.investigator")
                  }}</span>
                  : {{ books[0].investigator }}
                </v-card-subtitle>
                <v-card-subtitle class="pa-0 py-1" v-if="books[0].reviewer">
                  <span class="primary--text">{{
                    $t("library.reviewer")
                  }}</span>
                  : {{ books[0].reviewer }}
                </v-card-subtitle>
                <v-card-subtitle
                  class="pa-0 py-1"
                  v-if="books[0].publishedYear"
                >
                  <span class="primary--text">{{
                    $t("library.publishedYear")
                  }}</span>
                  : {{ books[0].publishedYear }}
                </v-card-subtitle>
                <v-card-subtitle class="pa-0 py-1" v-if="books[0].languages">
                  <v-chip-group column>
                    <v-chip
                      class="ma-0 mx-1"
                      v-for="tag in books[0].languages.split(';')"
                      :key="tag"
                    >
                      {{ tag }}
                    </v-chip>
                  </v-chip-group>
                </v-card-subtitle>
              </v-card-text>
            </v-col>
            <v-col cols="12" lg="12" md="12" sm="12">
              <v-card-text class="pt-0 text-justify" v-if="books[0].summary">
                {{ books[0].summary }}
              </v-card-text>
              <v-card-subtitle>
                <v-card class="mb-0" :width="250" outlined>
                  <v-list-item three-line>
                    <div>
                      <files-icon
                        class="py-3"
                        :fileType="get_url_extension(books[0].fileUrl)"
                        :width="50"
                        :showBadge="false"
                      ></files-icon>
                    </div>

                    <v-list-item-content class="px-2">
                      <v-list-item-subtitle>
                        <span>({{ books[0].fileSizeFormated }})</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-card-actions>
                    <v-btn
                      class="px-3"
                      outlined
                      rounded
                      text
                      @click="downloadBook(books[0].fileUrl)"
                    >
                      {{ $t("Download") }}
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-card-subtitle>
              <v-divider></v-divider>

              <v-card-title class="primary--text" v-if="books[0].published">
                {{ $t("library.published") }}
              </v-card-title>

              <v-card-text
                v-if="books[0].published"
                style="white-space: pre-line !important"
                class="text-justify pt-0 pb-3"
              >
                {{ books[0].published }}
              </v-card-text>
              <v-divider></v-divider>
              <v-card-title
                class="primary--text"
                v-if="books[0].subjectHeadings"
              >
                {{ $t("library.contents") }}
              </v-card-title>
              <v-card-text
                v-if="books[0].subjectHeadings"
                style="white-space: pre-line !important"
                class="text-justify pt-0 pb-3"
              >
                {{ books[0].subjectHeadings }}
              </v-card-text>
              <v-divider></v-divider>
              <v-card-title class="primary--text" v-if="books[0].aboutAuthor">
                {{ $t("library.aboutAuthor") }}
              </v-card-title>
              <v-card-text
                v-if="books[0].aboutAuthor"
                style="white-space: pre-line !important"
                class="text-justify pt-0 pb-3"
              >
                {{ books[0].aboutAuthor }}
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>

        <v-col cols="12" md="12" class="text-center mt-10" v-if="!books[0]">
          <img src="../../assets/folder.svg" width="150px" />
          <h3>{{ $t("library.noInfo") }}</h3>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import filesIcon from "../../components/filesIcon.vue";

export default {
  components: {
    filesIcon,
  },
  data() {
    return {
      Categories: [],
      CategoriesTree: [],
      open: [],
      active: [],
      currentCategory: [],
      initiallyOpen: [],
      treeDrawer: true,
      treeDrawerWidth: 250,
      innerWidth: 0,
      nvaCols: 2,
      booksCols: 10,
      books: [],
      loadingDataCategories: false,
      loadingDataBooks: false,
      bookKeysSelected: null,
      watchedBook: false,
      searchCategory: "",
      searchBook: "",
      page: 1,
      perPage: 8,
    };
  },
  created() {
    this.page = 1;

    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1340) {
      this.nvaCols = 0;
      this.booksCols = 12;
      this.treeDrawer = false;
    }

    this.getCategories();
    if (this.getAllBooks()) {
      this.getBooks(this.active[0]);
    } else {
      this.getBooks("00000000-0000-0000-0000-000000000000", true);
    }
  },
  computed: {
    visiblePages() {
      return this.booksFilter.slice(
        (this.page - 1) * this.perPage,
        this.page * this.perPage
      );
    },

    booksFilter() {
      return this.books.filter((item) => this.searchBook
          .toLowerCase()
          .split(" ")
          .every(
            (v) => (item.bookTitle && item.bookTitle.toLowerCase().includes(v)) ||
              (item.author && item.author.toLowerCase().includes(v)) ||
              (item.categoryName &&
                item.categoryName.toLowerCase().includes(v)) ||
              (item.investigator &&
                item.investigator.toLowerCase().includes(v)) ||
              (item.published && item.published.toLowerCase().includes(v)) ||
              (item.reviewer && item.reviewer.toLowerCase().includes(v)) ||
              (item.publishedYear && item.publishedYear == v) ||
              (item.summary && item.summary.toLowerCase().includes(v)) ||
              (item.translator && item.translator.toLowerCase().includes(v))
          ));
    },
    bookId: {
      get() {
        if (this.$route.query.bookId) {
          return this.$route.query.bookId;
        }
        return "00000000-0000-0000-0000-000000000000";
      },
      set(newVal) {
        return newVal;
      },
    },
  },
  watch: {
    active() {
      this.page = 1;
    },
    treeDrawer() {
      if (this.treeDrawer) {
        this.treeDrawerWidth = 250;
      } else {
        this.treeDrawerWidth = 0;
      }
    },
    bookId(newVal, oldVal) {
      // if (oldVal == newVal) {
      //   return;
      // }
      if (!this.getAllBooks()) {
        this.getBooks("00000000-0000-0000-0000-000000000000", true);
      } else if (this.active && this.active.length > 0) {
          this.getBooks(this.active[0]);
        } else {
          this.books = [];
        }
    },
  },
  methods: {
    getCategories() {
      this.loadingDataCategories = true;
      this.Categories = [];
      this.CategoriesTree = [];
      this.axios
        .get(`Library/GetCategories`)
        .then((res) => {
          this.Categories = res.data.data;

          const general = {
            guid: "00000000-0000-0000-0000-000000000000",
            categoryName: this.$t("library.all"),
            parentGuid: "00000000-0000-0000-0000-000000000000",
            children: [],
            booksCount: this.Categories.filter(
              (m) => m.parentGuid == "00000000-0000-0000-0000-000000000000"
            ).reduce((n, { booksCount }) => n + booksCount, 0),
          };
          for (let i = 0; i < this.Categories.length; i++) {
            var tree = this.Categories[i];
            const children = this.Categories.filter((f) => f.parentGuid == tree.guid);

            if (children.length > 0) {
              children.forEach((child) => {
                const childNode = { ...tree, item: child, vnode: null };
                this.$refs.treeReference.nodes[child.guid] = childNode;
              });
              tree.children = children;
            }
            if (
              this.Categories[i].parentGuid ==
              "00000000-0000-0000-0000-000000000000"
            ) {
              general.children.push(tree);
            }
          }
          this.CategoriesTree.push(general);
          this.initiallyOpen.push("00000000-0000-0000-0000-000000000000");
        })
        .catch(() => {})
        .finally(() => {
          this.loadingDataCategories = false;
        });
    },
    getBooks(e, openBook = false) {
      if (this.getAllBooks() || !openBook) {
        // this.bookId = "00000000-0000-0000-0000-000000000000";
        this.$router
          .replace({ query: {} })
          .catch((err) => {})
          .finally(() => {});
      }
      if (!e || e.length == 0 || e == undefined) {
        this.books = [];
        return;
      }

      this.loadingDataBooks = true;
      this.axios
        .get(`Library/Get?categoryGuid=${e}&bookGuid=${this.bookId}`)
        .then((res) => {
          this.books = res.data.data;
        })
        .catch(() => {})
        .finally(() => {
          this.loadingDataBooks = false;
        });
    },
    openBook(item) {
      this.$router
        .replace({ query: { bookId: item.bookGuid } })
        .catch((err) => {})
        .finally(() => {});
      this.bookId = item.bookGuid;
    },
    downloadBook(url) {
      this.axios
        .post(`Uploader/Download`, {
          fileUrl: url,
        })
        .then((res) => {
          window.open(res.data.data.url, "_blank");
        })
        .catch((err) => {})
        .finally(() => {});

      // window.open(item.fileUrl, "_blank");
    },
    onResize(e) {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1340) {
        this.nvaCols = 0;
        this.booksCols = 12;
      } else if (this.innerWidth >= 1340 && this.innerWidth <= 1650) {
        this.treeDrawer = true;
        this.nvaCols = 3;
        this.booksCols = 9;
      } else {
        this.treeDrawer = true;
        this.nvaCols = 2;
        this.booksCols = 10;
      }
    },
    getAllBooks() {
      if (
        this.bookId == "00000000-0000-0000-0000-000000000000" ||
        !this.bookId ||
        this.bookId == undefined
      ) {
        return true;
      }
      return false;
    },
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim().toLowerCase();
    },
  },
};
</script>
<style >
.book-tree .v-navigation-drawer__content {
  padding-bottom: 0 !important;
}
.books.v-card.on-hover .v-card__title {
  background-color: rgb(0 0 0 / 77%) !important;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  color: aliceblue;
}
.books.v-card .v-card__title {
  background-color: rgb(0 0 0 / 55%) !important;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  color: aliceblue;
}
</style>